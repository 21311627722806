import { Injectable } from '@angular/core';
import { ApiServiceService } from '../../../../services/api-service.service';
import { BehaviorSubject, catchError, throwError } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WithdrawalRequestsService {

  refreshList = new BehaviorSubject<boolean>(false);

  constructor(private _apiService: ApiServiceService) { }

  GetWithdrawalRequests(model: any) {
    return this._apiService.get(`${environment.apiUrl}/vendors-money-withdraw-request/vendor`, model)
      .pipe(catchError(this.handleError));
  }

  GetWithdrawalRequestById(id: any) {
    return this._apiService.post(`${environment.apiUrl}/vendors-money-withdraw-request/request-details/?request_id=${id}`)
      .pipe(catchError(this.handleError));
  }

  SendWithdrawalRequest(model: any) {
    return this._apiService.post(`${environment.apiUrl}/vendors-money-withdraw-request`, model)
      .pipe(catchError(this.handleError));
  }


  handleError(error: any) {
    return throwError(error);
  }
}
